import {
  CameraIcon,
  ExclamationTriangleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  ShareIcon,
  Square2StackIcon,
  StarIcon,
} from '@heroicons/react/24/solid'
import UploadImage from '@/components/UploadImage'
import { useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useRouter } from 'next/router'

type Props = {
  recordId: number
  recordType: string
  liked: string
  onReportClick: () => void
  locationUrl?: string
}

const DetailActionButtons = ({
  recordId,
  recordType,
  liked,
  onReportClick,
  locationUrl,
}: Props) => {
  const [triggerImageUpload, setTriggerImageUpload] = useState<boolean>(false)
  const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0()
  const [trigger, setTrigger] = useState(false)
  const [isLiked, setIsLiked] = useState(liked)
  const [sharetrigger, setShareTrigger] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setIsLiked(liked)
  }, [liked])
  const action = async (type: string) => {
    const token = await getAccessTokenSilently()
    const apiUrl = process.env.NEXT_PUBLIC_API_URL

    let state = type === 'like' ? 'true' : 'false'

    if (state == isLiked || state === isLiked) {
      state = 'delete'
      setIsLiked('')
    }

    const data = {
      user_id: user?.sub,
      item_id: recordId,
      like: state,
      type: recordType,
    }

    const res = await axios.post(
      `${apiUrl}/user/${recordId}/likedislike`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    setIsLiked(state)
  }

  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = window.location.origin + router.asPath
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <>
      <Modal state={trigger} setTrigger={setTrigger}>
        <div
          className={`text-link text-center cursor-pointer`}
          onClick={() => loginWithRedirect()}
        >
          Signup / Login to perform this action
        </div>
      </Modal>

      <Modal state={sharetrigger} setTrigger={setShareTrigger}>
        <div className="flex flex-col justify-center space-y-2">
          <button
            onClick={copyToClipboard}
            className="flex flex-row justify-center items-center bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            <Square2StackIcon className={`w-6 h-6 mr-2`} />{' '}
            {copied ? 'Copied!' : 'Copy link'}
          </button>

          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://yumdodo.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center bg-[#3B5998] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Share on Facebook
          </a>

          <a
            href="https://twitter.com/intent/tweet?url=https://yumdodo.com/&text=Check%20out%20YumDodo!"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
          >
            Share on Twitter
          </a>
        </div>
      </Modal>

      <UploadImage
        itemId={recordId}
        setTrigger={setTriggerImageUpload}
        trigger={triggerImageUpload}
        type={recordType}
      />

      <div
        className={`flex flex-row items-center gap-2 px-2 py-0 w-full text-white`}
      >
        {/*<div*/}
        {/*  className={`flex flex-col items-center space-y-2 w-1/5 cursor-pointer`}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className={`flex flex-row items-center rounded-full bg-lightdark w-12  space-x-2 h-12 justify-center`}*/}
        {/*  >*/}
        {/*    <StarIcon className={`w-6 h-6 self-center mx-auto text-white`} />*/}
        {/*  </div>*/}
        {/*  <p className={`text-xs block`}>Add Review</p>*/}
        {/*</div>*/}

        <div
          className={`flex flex-col items-center space-y-2 w-1/4 cursor-pointer`}
          onClick={() => setShareTrigger(true)}
        >
          <div
            className={`flex flex-row items-center rounded-full bg-lightdark w-12 space-x-2 h-12 justify-center`}
          >
            <ShareIcon className={`w-6 h-6 self-center mx-auto text-white`} />
          </div>
          <p className={`text-xs block`}>Share</p>
        </div>

        <div
          className={`flex flex-col items-center space-y-2 w-1/4 cursor-pointer`}
          onClick={() =>
            isAuthenticated ? setTriggerImageUpload(true) : setTrigger(true)
          }
        >
          <div
            className={`flex flex-row items-center rounded-full bg-lightdark w-12 space-x-2 h-12 justify-center`}
          >
            <CameraIcon className={`w-6 h-6 self-center mx-auto text-white`} />
          </div>
          <p className={`text-xs block`}>Add Photo</p>
        </div>

        <div
          className={`flex flex-col items-center space-y-2 w-1/4 cursor-pointer`}
          onClick={() => {
            isAuthenticated ? action('like') : setTrigger(true)
          }}
        >
          <div className={`flex rounded-full bg-lightdark w-12 h-12`}>
            <HandThumbUpIcon
              className={`w-6 h-6 self-center mx-auto ${isLiked === 'true' ? ' text-sky-400' : 'text-white'}`}
            />
          </div>
          <p className={`text-xs block`}>Like</p>
        </div>
        <div
          className={`flex flex-col items-center space-y-2 w-1/5 cursor-pointer`}
          onClick={() => {
            isAuthenticated ? action('dislike') : setTrigger(true)
          }}
        >
          <div className={`flex rounded-full bg-lightdark w-12 h-12`}>
            <HandThumbDownIcon
              className={`w-6 h-6 self-center mx-auto ${isLiked === 'false' ? ' text-sky-400' : 'text-white'}`}
            />
          </div>
          <p className={`text-xs block`}>Dislike</p>
        </div>
      </div>
    </>
  )
}

export default DetailActionButtons
