// @ts-nocheck
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  PhotoIcon,
} from '@heroicons/react/24/solid'
import { FoodMerchant, Restrictions } from '@/types/FoodMerchant'
import { useEffect, useState } from 'react'
import { checkPlatform } from '@/util/DeviceCheck'
import ReportPopup from '@/components/ReportPopup'
import UploadImage from '@/components/UploadImage'
import Link from 'next/link'
import HourBlock from '@/components/HourBlock'
import Accordion from '@/components/Accordion'
import FooodActionButtons from '@/components/FoodCard/FoodActionButtons'
import PhotoViewer from '@/components/PhotoViewer'
import axios from 'axios'

type Props = {
  selectedItem: FoodMerchant | null
  selectedMapMarker: number
  setSelectedMapMarker: (props: number) => void
}
const DetailCard = (props: Props) => {
  const { selectedItem: item, selectedMapMarker, setSelectedMapMarker } = props
  // const [item, setItem] = useState(null)
  const [viewState, setViewState] = useState(false)
  const [reportItem, setReportItem] = useState(false)
  const [viewerOpen, setViewerOpen] = useState(false)
  const [triggerImageUpload, setTriggerImageUpload] = useState<boolean>(false)

  const levels: Record<string, string> = {
    '2': 'Very Likely',
    '1': 'Likely',
    '-1': 'Unlikely',
    '-2': 'Very Unlikely',
  }

  const Confidence = (props: { confidence: number }) => {
    const { confidence } = props
    return (
      <div className="flex gap-1 w-max mx-auto text-xs text-center bg-gray-500 px-2 py-1 rounded-r-md">
        {levels[confidence.toString()]}
      </div>
    )
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const platForm = checkPlatform()
      if (platForm === 'isApple' || platForm === 'isIOS') {
        setViewState(true)
      }
    }
  }, [])

  return (
    <>
      {reportItem && (
        <ReportPopup
          trigger={reportItem}
          setTrigger={setReportItem}
          itemId={item.id}
        />
      )}

      <UploadImage
        itemId={item?.id}
        setTrigger={setTriggerImageUpload}
        trigger={triggerImageUpload}
        type={'fooditem'}
      />
      <div
        className="absolute top-2 left-2 bg-white text-black rounded-md p-1 cursor-pointer z-20 border-dark border-2 dark:border-0"
        onClick={() => setSelectedMapMarker(0)}
      >
        <ArrowLeftIcon className={`h-6 w-auto `} />
      </div>

      <div
        className={`relative h-detailcard overflow-auto scroll-auto space-y-2 no-scrollbar rounded-md bg-gray-200 dark:bg-dark
          ${selectedMapMarker !== 0 ? 'block opacity-100' : 'hidden opacity-0'}
          `}
      >
        <div className={`relative`}>
          <div className={'image-slider no-scrollbar w-full'}>
            {item?.images?.length > 0 ? (
              item?.images.map((i) => (
                <div className={`relative`} key={i.id}>
                  <img
                    src={`${process.env.NEXT_PUBLIC_API_URL}/assets/images/${i.id}/${i.path_to_image}.jpg`}
                    className={`h-[300px] rounded-md object-cover max-w-none
                  ${item.images.length < 2 ? 'w-full' : 'w-[300px]'}
                  `}
                    alt={item?.name}
                  />

                  {item?.images?.length > 0 && (
                    <div
                      className={
                        'absolute z-20 bottom-6 left-1 bg-opacity-70 bg-white rounded-md text-black p-1 text-sm'
                      }
                    >
                      {item.images[0]?.attribution}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <img
                src={`${process.env.NEXT_PUBLIC_API_URL}/assets/dodo-placeholder.jpg`}
                alt={item?.name}
                className={`h-[300px] rounded-md object-cover max-w-none w-full`}
              />
            )}
          </div>
          {item?.images && item?.images.length > 1 && (
            <div className="absolute top-2 right-2 bg-white text-black rounded-md p-1 cursor-pointer">
              <PhotoIcon
                className="h-6 w-auto "
                onClick={() => {
                  setViewerOpen(true)
                }}
              />
            </div>
          )}
          {viewerOpen && (
            <PhotoViewer
              trigger={viewerOpen}
              setTrigger={setViewerOpen}
              images={item.images}
            />
          )}

          <div
            className={`absolute bottom-4 h-28 w-full p-2 rounded-b-md
              bg-gradient-to-t from-gray-900 to-transparent text-white`}
          >
            {item?.merchant_name && <p className={`text-2xl`}>{item.name}</p>}
            {item?.price !== 0 && (
              <p className="text-sm">{'$' + item?.price}</p>
            )}
          </div>
        </div>
        <FooodActionButtons
          recordId={item?.id}
          recordType={`food_item`}
          liked={item.liked}
        />

        <div className={`block px-4`}>
          <hr className={`my-4 border-black dark:border-[#9797AA]`} />

          <Link
            href={`/merchant?slug=${item?.merchant_id}`}
            className={`text-blue-400 font-bold underline ${item?.merchant_name ? 'text-sm' : 'text-lg'}`}
            data-test-id="detail-link"
          >
            {item?.merchant_name ? item?.merchant_name : item.name}
          </Link>

          <HourBlock hours={item.hours} />
          <p className={`text-sm`}>{item.location}</p>
          <a
            href={
              viewState
                ? `maps:?q=${item.coordinates.latitude},${item.coordinates.longitude}`
                : `https://maps.google.com?q=${item.name} ${item.location}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-block w-full text-center rounded-md bg-primary mt-4 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm`}
          >
            Navigation
          </a>

          <hr className={`my-4 border-black dark:border-[#9797AA]`} />

          {item.description && (
            <>
              <Accordion title={`Description`} isOpen={true}>
                {item.description}
              </Accordion>
              <hr className={`my-4 border-black dark:border-[#9797AA]`} />
            </>
          )}

          <div
            className="flex gap-2 items-center py-2 flex-wrap"
            data-test-id="restriction-list"
          >
            {item?.restrictions &&
              item?.restrictions.map(
                (restriction: Restrictions) =>
                  restriction.type === 'restriction' && (
                    <div
                      key={restriction?.id}
                      className="text-sm capitalize content-stretch text-white items-center bg-gray-700 rounded-md pl-2 flex gap-1"
                    >
                      {restriction?.name || 'TBD'}
                      <Confidence confidence={restriction?.level} />
                    </div>
                  )
              )}
          </div>

          <div
            className={`flex flex-row gap-2 my-4 cursor-pointer text-black dark:text-white bg-accent dark:bg-lightdark px-3.5 py-2.5 w-full rounded-md justify-center`}
            onClick={() => setReportItem(true)}
          >
            <ExclamationTriangleIcon className={`h-6 w-auto cursor-pointer`} />
            Report
          </div>
        </div>

        <div className={`h-20`}></div>
      </div>
    </>
  )
}
export default DetailCard

const Separate = () => (
  <svg
    className={`inline-block ml-0.5 mr-1`}
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="2"
    viewBox="0 0 2 2"
    fill="none"
  >
    <circle cx="1" cy="1" r="1" fill="white" />
  </svg>
)
