const LoadingCard = () => {
  return (
    <div
      className={`relative bg-white border shadow-md rounded-md h-auto w-full min-w-64 flex flex-col`}
    >
      <div className="w-full h-[200px] rounded-t-md bg-gray-300 mb-2 skeleton-loading"></div>
      <div className={'px-2 h-full flex flex-col'}>
        <div className="h-20 bg-gray-300 mb-2 rounded-md skeleton-loading"></div>
      </div>
    </div>
  )
}

export default LoadingCard
