import posthog from 'posthog-js'
import { Coords } from '@/types/Coords'
import { FoodMerchant } from '@/types/FoodMerchant'

/**
 * Uses PostHog to log all details of a search query, including the results.
 */
export function SearchQueryCapture(
  event_name: string,
  query: string,
  filters: string[],
  cuisine: string[],
  coords: Coords,
  results: FoodMerchant[]
) {
  posthog.capture(event_name, {
    query: query,
    filters: filters,
    cuisine: cuisine,
    coords: coords,
    results: results,
  })
}
