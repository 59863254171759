import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import Modal from '@/components/Modal'

type Props = {
  itemId: number
  liked: string
  updateItem?: (id: number, state: string) => void
  recordType: string
}

const Rate = ({ itemId, liked, updateItem, recordType }: Props) => {
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } =
    useAuth0()
  const [trigger, setTrigger] = useState(false)
  const [isLiked, setIsLiked] = useState(liked)

  useEffect(() => {
    setIsLiked(liked)
  }, [liked])
  const action = async (type: string) => {
    const token = await getAccessTokenSilently()
    const apiUrl = process.env.NEXT_PUBLIC_API_URL

    let state = type === 'like' ? 'true' : 'false'

    if (state == isLiked || state === isLiked) {
      state = 'delete'
      setIsLiked('')
    }

    const data = {
      user_id: user?.sub,
      item_id: itemId,
      like: state,
      type: recordType,
    }

    const res = await axios.post(`${apiUrl}/user/${itemId}/likedislike`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (updateItem) {
      setIsLiked(state)
      updateItem(itemId, state)
    }
  }
  return (
    <div className={`flex flex-row gap-1`}>
      <Modal state={trigger} setTrigger={setTrigger}>
        <div
          className={`text-link text-center`}
          onClick={() => loginWithRedirect()}
        >
          Signup / Login to rate food items
        </div>
      </Modal>
      <div
        className={`text-black cursor-pointer`}
        onClick={() => {
          isAuthenticated ? action('like') : setTrigger(true)
        }}
      >
        <HandThumbUpIcon
          className={`h-6 w-auto cursor-pointer ${isLiked === 'true' ? ' text-sky-400' : 'text-black dark:text-white'}`}
        />
      </div>
      <div
        className={'text-black cursor-pointer'}
        onClick={() => {
          isAuthenticated ? action('dislike') : setTrigger(true)
        }}
      >
        <HandThumbDownIcon
          className={`h-6 w-auto cursor-pointer ${isLiked === 'false' ? ' text-red-400' : 'text-black dark:text-white'}`}
        />
      </div>
    </div>
  )
}
export default Rate
