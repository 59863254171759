interface Props {
  setSelectedMapMarker: (props: number) => void
}

const ErrorCard = (props: Props) => {
  const { setSelectedMapMarker } = props
  return (
    <>
      <div
        className={`relative h-detailcard space-y-1 scroll-auto no-scrollbar rounded-md bg-gray-200 dark:bg-dark`}
      >
        <div className="text-2xl">Item Loading Error</div>
        <div>
          An error has occured and the item you are searching for cannot be
          found. Please try going back.
        </div>
        <button
          type="button"
          onClick={() => {
            setSelectedMapMarker(0)
          }}
          className="bg-gray-200 text-black rounded-md p-1"
        >
          Go Back
        </button>
      </div>
    </>
  )
}

export default ErrorCard
