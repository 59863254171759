import { useFilterStore } from '@/hooks/useFilterStore'

const RestaurantsMenuToggle = () => {
  const searchType = useFilterStore((state) => state.searchType)
  const setSearchType = useFilterStore((state) => state.setSearchType)
  const setSelectedCard = useFilterStore((state) => state.setSelectedCard)
  const setSearchState = useFilterStore((state) => state.setSearchState)
  return (
    <div className={'rounded-md flex h-9'}>
      <div
        onClick={() => {
          setSearchState(true)
          setSearchType('restaurants')
          setSelectedCard(0)
        }}
        className={`
              ${
                searchType.includes('restaurants')
                  ? 'bg-black text-white dark:bg-white dark:text-black'
                  : 'bg-white text-black dark:bg-lightdark dark:text-white'
              }
               items-center justify-center h-auto w-1/2 mb-1 relative inline-flex rounded-l-md focus:outline-none cursor-pointer`}
      >
        Restaurants
      </div>

      <div
        onClick={() => {
          setSearchState(true)
          setSearchType('meals')
          setSelectedCard(0)
        }}
        className={`
              ${
                searchType.includes('meals')
                  ? 'bg-black text-white dark:bg-white dark:text-black'
                  : 'bg-white text-black dark:bg-lightdark dark:text-white'
              }
               items-center justify-center h-auto w-1/2 mb-1 relative inline-flex rounded-r-md focus:outline-none cursor-pointer`}
      >
        Meals
      </div>
    </div>
  )
}

export default RestaurantsMenuToggle
