import { useEffect, useRef, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { useFilterStore } from '@/hooks/useFilterStore'

const SearchBar = () => {
  const resetQuery = useFilterStore((state) => state.resetQuery)
  const setQuery = useFilterStore((state) => state.setQuery)
  const setSearchState = useFilterStore((state) => state.setSearchState)
  const resetSelectedCard = useFilterStore((state) => state.resetSelectedCard)
  const searchInput = useFilterStore((state) => state.searchInput)
  const setSearchInput = useFilterStore((state) => state.setSearchInput)
  const searchType = useFilterStore((state) => state.searchType)
  const inputRef = useRef<HTMLInputElement>(null)

  const dismissKeyboard = () => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  const onSearch = () => {
    resetSelectedCard()
    setSearchState(true)
    setQuery(searchInput)
  }

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value ?? '')
  }

  return (
    <div
      className={`${
        searchInput.length > 0 && 'left-20'
      } sm:left-0 flex items-center w-full`}
      id="search"
      data-test-id="search-bar"
    >
      <div className="flex rounded-md shadow-sm w-full">
        <div className="relative flex flex-grow items-stretch">
          {searchInput.length > 0 && (
            <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3">
              <XCircleIcon
                className="h-5 w-5 text-gray-400 hover:text-black "
                aria-hidden="true"
                onClick={(e) => {
                  resetQuery()
                  setSearchInput('')
                  setSearchState(true)
                  if (inputRef.current) {
                    inputRef.current.focus()
                  }
                }}
              />
            </div>
          )}
          <input
            ref={inputRef}
            value={searchInput}
            onChange={onChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dismissKeyboard()
                onSearch()
              }
            }}
            type="text"
            name="search-input"
            id="search-input"
            className={`block bg-[#F3F5F8] w-full rounded-none rounded-l-md shadow-inner border-none py-2 focus:outline-none focus:ring-0 ${
              searchInput.length > 0 ? 'pl-10' : 'pl-2'
            } text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            placeholder={
              searchType === 'restaurants'
                ? 'Enter a restaurant'
                : 'Enter an item to search'
            }
          />
        </div>
        <button
          type="button"
          id="searchButton"
          aria-label="Search Button"
          className="bg-[#F3F5F8] relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md p-2 text-sm font-semibold text-gray-400"
          onClick={(e) => {
            onSearch()
            dismissKeyboard()
          }}
        >
          <MagnifyingGlassIcon className={`h-4 w-auto text-black`} />
        </button>
      </div>
    </div>
  )
}

export default SearchBar

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-6 h-6 mt-1"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
    />
  </svg>
)
