import { useCallback, useEffect, useState } from 'react'
import axios, { isCancel } from 'axios'
import { Coords } from '@/types/Coords'
import { FoodMerchant } from '@/types/FoodMerchant'
import { SearchQueryCapture } from '@/util/PosthogCaptures'
import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

const useSearch = (
  query: string = '',
  filters: string[] = [],
  cuisines: string[],
  coords: Coords,
  distance: string,
  state: boolean = true,
  initialZoom: string,
  searchType: string,
  selectedCard: number
) => {
  const router = useRouter()
  const { user } = useAuth0()

  const updateQueryParams = (newParams: Record<string, string | number>) => {
    if (router.isReady) {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          ...newParams,
        },
      })
    }
  }

  const joinedCuisines = cuisines.join(',')
  const joinedFilters = filters.join(',')
  const location = `${coords.latitude},${coords.longitude}`

  const queryKeys = {
    query: query,
    cuisines: joinedCuisines,
    filters: joinedFilters,
    distance: distance,
    location: location,
    searchType: searchType,
    userId: user?.sub,
  }

  const useSearchQuery = async () => {
    const response = await axios
      .get(`${process.env.NEXT_PUBLIC_API_URL}/search`, {
        params: {
          query,
          cuisines: joinedCuisines,
          filters: joinedFilters,
          distance: distance,
          searchType: searchType,
          location: location,
          userID: user?.sub,
        },
      })
      .catch((e) => {
        throw e
      })
    return response
  }

  const {
    data,
    error,
    isPending: loadingResults,
    status,
  } = useQuery({
    queryKey: ['useSearch', queryKeys],
    queryFn: useSearchQuery,
    select: (d) => (d ? d.data : []),
  })

  if (error) {
    Sentry.captureException(`failed to fetch search: ${error}`)
    SearchQueryCapture(
      'search:query_failed',
      query,
      filters,
      cuisines,
      coords,
      []
    )
  }

  useEffect(() => {
    console.log('called')
    if (data || error) {
      updateQueryParams({
        q: query,
        d: distance,
        f: joinedFilters,
        c: location,
        st: searchType,
        z: initialZoom,
        sc: selectedCard,
      })
    }
  }, [
    data,
    error,
    query,
    distance,
    joinedFilters,
    location,
    searchType,
    initialZoom,
    selectedCard,
  ])

  //this should not be in here
  useEffect(() => {
    updateQueryParams({
      sc: selectedCard,
    })
  }, [selectedCard])

  // console.log(
  //   'search condition',
  //   state && coords.longitude !== 0 && parseInt(distance.split(',')[0]) > 1
  // )
  //
  // console.log('search long', state && coords.longitude !== 0)
  // console.log('search ditance', parseInt(distance.split(',')[0]) > 1)
  // console.log('search isnum', parseInt(distance.split(',')[0]))

  // should also return an error
  return { loadingResults, data, error }
}

//may need if cancel actually works
// } catch (error) {
//   setLoading(true)
//   if (isCancel(error)) {
//     SearchQueryCapture(
//       'search:query_canceled',
//       query,
//       filters,
//       cuisines,
//       coords,
//       []
//     )
//   } else {
//     Sentry.captureException(`failed to fetch search: ${error}`)
//     SearchQueryCapture(
//       'search:query_failed',
//       query,
//       filters,
//       cuisines,
//       coords,
//       []
//     )
//   }

export default useSearch
