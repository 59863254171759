import { MobileView } from '@/types/FoodMerchant'
import { FunnelIcon } from '@heroicons/react/24/solid'
import RestaurantsMenuToggle from './RestaurantsMenuToggle'
import PillFiltersContainer from './PillFiltersContainer'

interface Props {
  handleMobileView: (id: MobileView) => void
}

const customPills = [
  'gluten-free',
  'vegan',
  'low glycemic index',
  'dairy-free',
  'halal',
  'heart healthy',
  'kosher',
  'low-lactose',
]

const MobileHeader = (props: Props) => {
  const { handleMobileView } = props
  return (
    <div>
      <div className="grid grid-cols-3 gap-2 items-stretch ">
        <div
          className="col-span-1 h-8 rounded-lg border border-white flex font-semibold justify-center items-center space-x-2"
          onClick={() => handleMobileView('filters')}
        >
          <FunnelIcon className="w-5 h-5" />
          <p>All Filters</p>
        </div>
        <div className="col-span-2 ">
          <RestaurantsMenuToggle />
        </div>
      </div>
      <div className="flex gap-2 items-center justify-center">
        <p className="text-nowrap text-center font-semibold">Popular:</p>

        <span className="flex overflow-x-scroll">
          <PillFiltersContainer customList={customPills} />
        </span>
      </div>
    </div>
  )
}

export default MobileHeader
