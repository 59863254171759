import { useState, useEffect, useRef, useMemo } from 'react'
import { FoodMerchant, MobileView } from '@/types/FoodMerchant'
import FoodCard from '@/components/FoodCard/FoodCard'
import dynamic from 'next/dynamic'
import LoadingCard from '@/components/FoodCard/LoadingCard'
import DetailCard from '@/components/FoodCard/DetailCard'
import ErrorCard from '@/components/ErrorCard'
import MapFiltersBar from '@/components/MapFiltersBar'
import { XMarkIcon } from '@heroicons/react/20/solid'
import MerchantDetailCard from '@/components/MerchantDetailCard'
import axios from 'axios'
import { useFilterStore } from '@/hooks/useFilterStore'
import SearchBar from './SearchBar'
const Map = dynamic(() => import('@/components/Map'), { ssr: false })

type Props = {
  loadingResults: boolean
  searchResults: FoodMerchant[]
  mapCoords: number[]
  setMapCoords: (val: number[]) => void
  setDistance: (val: string) => void
  distance: string
  initialZoom: string
  setZoomLevel: (val: string) => void
  selectedCard: number
  setSelectedCard: (val: number) => void
  mobileView: MobileView
  handleMobileView: (id: MobileView) => void
}

const MapView = (props: Props) => {
  const {
    loadingResults,
    searchResults,
    mapCoords,
    setMapCoords,
    setDistance,
    distance,
    initialZoom,
    setZoomLevel,
    selectedCard,
    setSelectedCard,
    mobileView,
    handleMobileView,
  } = props
  const [selectedMapMarker, setSelectedMapMarker] = useState(0)
  const [selectedItem, setSelectedItem] = useState<FoodMerchant | null>(null)
  const [scroll, setScroll] = useState(false)
  const [triggerItemUpdate, setTriggerItemUpdate] = useState({
    id: 0,
    state: '',
  })
  const [itemError, setItemError] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const sideBarRef = useRef<HTMLDivElement>(null)

  const searchType = useFilterStore((state) => state.searchType)
  const priceFilter = useFilterStore((state) => state.priceFilter)
  const isMobile = useFilterStore((state) => state.isMobile)

  useEffect(() => {
    setItemError(false)
    setSelectedItem(null)
    if (selectedMapMarker != 0 && scrollContainerRef.current) {
      const selectedElement = scrollContainerRef.current.querySelector(
        `[data-food-id="${selectedMapMarker}"]`
      ) as HTMLElement

      if (selectedElement && scroll) {
        scrollContainerRef.current.scrollTop = selectedElement.offsetTop - 220
        setScroll(false)
      }

      let itemRes = filteredResults.find((x) => x.id == selectedMapMarker)

      if (searchType === 'meals' && itemRes === undefined) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${process.env.NEXT_PUBLIC_API_URL}/item/${selectedMapMarker}`
            )
            setSelectedItem(response.data)
          } catch (err) {
            setItemError(true)
            console.error(err)
          }
        }
        fetchData()
        return
      }
      if (itemRes === undefined) {
        setItemError(true)
        console.error('Item not found in search results')
        return
      }
      setSelectedItem(itemRes)
    }
  }, [selectedMapMarker])

  const maxPrice = useMemo(() => {
    if (searchType === 'restaurants') {
      return 0
    }
    if (searchResults.length === 0) {
      return 0
    }
    let max = -Infinity
    searchResults.forEach((item) => {
      if (item.price > max) {
        max = Math.ceil(item.price)
      }
    })
    return max
  }, [searchResults, searchType])

  const filteredResults = useMemo(() => {
    if (searchType === 'restaurants') {
      return searchResults
    }
    const res = searchResults.filter(
      (item) => item.price >= priceFilter.min && item.price <= priceFilter.max
    )
    return res
  }, [searchType, searchResults, priceFilter])

  useEffect(() => {
    if (selectedCard != 0 && filteredResults.length > 0) {
      setSelectedMapMarker(selectedCard)
    }
  }, [selectedCard, filteredResults])

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0
    }
    setSelectedMapMarker(0)
  }, [filteredResults])

  const displayItemsFoundIndicator = () => {
    if (loadingResults) {
      return <>Loading</>
    }

    if (filteredResults) {
      return <>{filteredResults.length} found</>
    }
    return null
  }

  const displaySelectedItem = () => {
    if (searchType === 'restaurants') {
      return (
        <MerchantDetailCard
          item={selectedItem}
          selectedMapMarker={selectedMapMarker}
          setSelectedMapMarker={(val) => {
            setSelectedMapMarker(val)
            setSelectedCard(val)
          }}
        />
      )
    }
    if (searchType === 'meals') {
      return (
        <DetailCard
          selectedItem={selectedItem}
          selectedMapMarker={selectedMapMarker}
          setSelectedMapMarker={(val) => {
            setSelectedMapMarker(val)
            setSelectedCard(val)
          }}
        />
      )
    }
  }

  const mobileDisplayView = useMemo(() => {
    let styles = {
      filtersBar: '',
      listBar: 'bottom-0 h-16 overflow-hidden',
      map: '',
    }
    if (isMobile) {
      switch (mobileView) {
        case 'map':
          styles.filtersBar = 'hidden'
          break
        case 'filters':
          styles = {
            filtersBar: 'top-0 h-auto',
            map: 'hidden',
            listBar: 'hidden',
          }
          break
        case 'list':
          styles = {
            filtersBar: 'hidden',
            map: '',
            listBar: 'top-0 h-auto',
          }
      }
    }
    return styles
  }, [isMobile, mobileView])

  return (
    <div
      className={`relative grid gap-1 grid-cols-1 md:grid-cols-mapFilter`}
      ref={sideBarRef}
    >
      <div
        id="filtersBar"
        className={`md:relative z-[3000] w-full md:h-maph dark:bg-dark ${mobileDisplayView.filtersBar} overflow-auto`}
      >
        <MapFiltersBar
          searchResults={filteredResults}
          maxPrice={maxPrice}
          loadingResults={loadingResults}
        />
      </div>

      <div
        className={`absolute md:relative z-[2200] w-full md:h-auto md:mt-[-4px] ${selectedMapMarker != 0 ? 'top-0 h-auto' : mobileDisplayView.listBar}`}
      >
        {itemError && <ErrorCard setSelectedMapMarker={setSelectedMapMarker} />}
        {filteredResults && selectedItem && displaySelectedItem()}
        <div
          id="list-toggle"
          className={`text-sm flex flex-row justify-between py-4 px-4 bg-white dark:bg-dark rounded-t-md
          ${selectedMapMarker == 0 ? '' : 'hidden'} cursor-pointer md:hidden`}
          onClick={() => handleMobileView('list')}
        >
          <div>
            <img
              className={`inline-block pr-2`}
              src="/assets/icons/foodicon.svg"
              alt="food icon"
            />
            {displayItemsFoundIndicator()}
          </div>

          {mobileView === 'list' ? (
            <div>
              <XMarkIcon
                className={`h-6 w-auto cursor-pointer block md:hidden`}
              />
            </div>
          ) : (
            <p className={`font-bold block md:hidden cursor-pointer`}>
              See list
            </p>
          )}
        </div>

        <div
          ref={scrollContainerRef}
          className={`relative h-maph space-y-2 no-scrollbar rounded-b-md bg-white dark:bg-dark p-2 shadow-xl dark:shadow-none
          ${selectedMapMarker == 0 ? 'block opacity-100' : 'hidden opacity-0'} 
          ${mobileView === 'list' || selectedMapMarker != 0 ? 'overflow-auto scroll-auto overflow-x-hidden' : 'overscroll-none md:overflow-auto'}
          `}
        >
          {loadingResults ? (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          ) : filteredResults && filteredResults.length === 0 ? (
            <div
              className={`p-2 bg-gray-300 rounded-lg my-4 text-center dark:bg-white dark:text-black`}
            >
              No Results
            </div>
          ) : (
            filteredResults &&
            filteredResults.length > 0 &&
            filteredResults.map((item, index) => (
              <div
                key={item.id}
                onClick={(e) => {
                  const element = e.target as HTMLElement
                  if (
                    element.tagName !== 'path' &&
                    element.tagName !== 'svg' &&
                    element.tagName !== 'A' &&
                    element.tagName !== 'SPAN' &&
                    element.id !== 'modal' &&
                    element.id !== 'hours' &&
                    element.id !== 'rate-container'
                  ) {
                    setSelectedMapMarker(item.id)
                    setSelectedCard(item.id)
                  }
                }}
                className={'cursor-pointer'}
                data-food-id={item.id}
              >
                <FoodCard
                  foodItem={item}
                  updateItem={(id, state) => {
                    setTriggerItemUpdate({ id, state })
                  }}
                  triggerItemUpdate={triggerItemUpdate}
                  recordType={
                    searchType === 'restaurants' ? 'merchant' : 'food_item'
                  }
                />
              </div>
            ))
          )}
        </div>
      </div>

      <div className={`row-start-1 md:row-start-auto ${mobileDisplayView.map}`}>
        <div className=" mt-1 flex flex-col gap-1">
          <SearchBar />
          <Map
            initialItems={filteredResults}
            selectedIndex={selectedMapMarker}
            initialMapCoords={mapCoords}
            setDistance={setDistance}
            setInitialMapCoords={setMapCoords}
            setSelectedMapMarker={(val) => {
              setScroll(true)
              setSelectedMapMarker(val)
            }}
            updateItem={(id, state) => {
              setTriggerItemUpdate({ id, state })
            }}
            triggerItemUpdate={triggerItemUpdate}
            loadingResults={loadingResults}
            distance={distance}
            initialZoom={initialZoom}
            setInitialZoomLevel={setZoomLevel}
            setSelectedCard={setSelectedCard}
          />
        </div>
      </div>
    </div>
  )
}

export default MapView
